<template>
  <v-container fluid class="page-container">
    <v-row style="flex-grow: 1">
      <v-col cols="12" class="pa-0 mb-10">
        <ComplianceHeader />
      </v-col>

      <v-col cols="11" class="mx-auto pa-2 mb-10">
        <router-link
          :to="`${
            redirectToExternalPage() ? '/external/compliance' : '/compliance'
          }`"
          class="home-link"
        >
          <h3>Página Inicial</h3>
        </router-link>

        <strong class="text-black"> > </strong>

        <h3 class="category-text">
          {{ categoryTitle }}
        </h3>
      </v-col>

      <v-col cols="12" class="pa-0 bg-primary text-white py-4 mb-10">
        <v-col cols="11" class="mx-auto pa-2 mb-10">
          <h1>Subcategorias - {{ categoryTitle }}</h1>

          <div
            v-if="category.subcategories.length === 0"
            class="my-8 text-center"
          >
            <h2>Nenhuma subcategoria encontrada.</h2>
          </div>

          <v-row v-else class="container-card">
            <v-col
              v-for="subcategory in category.subcategories"
              :key="'subcategory-' + subcategory.id"
              class="card"
            >
              <img
                :src="subcategory.image || defaultImage"
                alt="Imagem da Subcategoria"
                style="border-radius: 5px"
                width="100%"
                height="180px"
              >
              <h2 class="my-2">
                {{ subcategory.name }}
              </h2>

              <div style="flex-grow: 1" />

              <router-link
                class="custom-btn"
                :to="`${
                  redirectToExternalPage() ? '/external' : ''
                }/compliance/subcategory/${subcategory.id}`"
              >
                <div class="custom-btn-bg">
                  <v-icon color="#173c93" size="25px">
                    mdi-arrow-right
                  </v-icon>
                </div>
                <p class="custom-btn-text">
                  Visualizar
                </p>
              </router-link>
            </v-col>
          </v-row>
        </v-col>
      </v-col>

      <v-col cols="12" class="pa-0">
        <v-col cols="11" class="mx-auto pa-2 mb-4">
          <h1 class="mb-8">
            Compliances
          </h1>

          <div v-if="paginatedArticles.length === 0" class="my-8 text-center">
            <h2>Nenhuma publicação encontrada.</h2>
          </div>

          <v-row v-else>
            <v-col
              v-for="article in paginatedArticles"
              :key="article.id"
              cols="12"
              md="4"
              sm="6"
            >
              <v-card
                height="500"
                elevation="10"
                class="mx-auto d-flex flex-column justify-space-between"
                color="white lighten-5"
                style="
                  border-radius: 15px;
                  transition: transform 0.3s;
                  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
                "
              >
                <v-img
                  class="white--text align-end"
                  height="200px"
                  :src="article.featuredImage"
                  style="
                    border-top-left-radius: 15px;
                    border-top-right-radius: 15px;
                  "
                />
                <v-card-subtitle
                  class="text-uppercase pt-5 px-6 pb-0"
                  style="color: #616161"
                >
                  {{ article.createdAt | dateFormatted }}
                </v-card-subtitle>
                <v-card-text class="py-0 px-2 text-card">
                  <v-card-title class="text-truncate" style="font-weight: 600">
                    {{ article.headline }}
                  </v-card-title>
                  <v-card-subtitle class="text-truncate" style="color: #757575">
                    {{ article.caption }}
                  </v-card-subtitle>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    outlined
                    color="secondary"
                    :to="`${
                      redirectToExternalPage()
                        ? '/external/read-compliance/'
                        : '/read-compliance/'
                    }${article.slug}`"
                    style="border-radius: 20px; padding: 5px 20px; margin: 20px"
                  >
                    Ver mais
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-col>

      <v-col cols="12" class="text-center">
        <v-pagination
          v-model="currentPage"
          :length="pageCount"
          color="#173c93"
        />
      </v-col>

      <v-col cols="12" class="pa-0 mt-4">
        <ComplianceFooter />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { FindCategoryById } from "@/services/compliance-category-service";
import ComplianceFooter from "@/components/Compliance/ComplianceFooter.vue";
import ComplianceHeader from "@/components/Compliance/ComplianceHeader.vue";
import DefaultImage from "@/assets/compliance-default.webp";

export default {
  name: "ComplianceCategoryGrid",
  components: {
    ComplianceHeader: ComplianceHeader,
    ComplianceFooter: ComplianceFooter,
  },
  data() {
    return {
      defaultImage: DefaultImage,
      category: {
        id: undefined,
        title: "",
        featuredImage: "",
        subcategories: [],
        compliances: [],
      },
      categoryTitle: "",
      currentPage: 1,
      itemsPerPage: 6,
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.category.compliances.length / this.itemsPerPage);
    },
    paginatedArticles() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.category.compliances.slice(start, end);
    },
  },
  async created() {
    this.category = await FindCategoryById(this.$route.params.id);
    this.category.compliances = this.category.compliances.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
    this.categoryTitle = this.category
      ? this.category.title
      : "Categoria não encontrada";
  },
  methods: {
    redirectToExternalPage() {
      return this.$route.name && this.$route.name.startsWith("external");
    },
  },
};
</script>

<style scoped>
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.home-link {
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
  color: #173c93;
  display: inline-block;
}

.category-text {
  font-weight: bold;
  display: inline-block;
}

.v-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}
</style>
